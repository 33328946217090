#project-container {
    margin-top: 50px;
    max-width: 90%;
    align-self: center;
}
.card > .image {
    width: 100%;
    height: auto;
}
.card:hover {
    transform: scale(102%);
}