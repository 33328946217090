footer {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;

}
footer > p{
    margin:0;
}
.foot-container {
    margin-top: 10px;
}