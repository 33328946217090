body {
  background-color: #444;
  color: white;
}
main {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 70vh;
}
a {
  color: red;
}
a:hover {
  color: red;
  text-decoration: underline;
}
main > section {
  width: 90%;
  margin: 50px auto 50px auto;
}
.res-head {
  color: red;
  background-color: black;
}
.App {
  text-align: center;
}
#welcome {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#content-wrapper {
  width: 50%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#technologies > h3 {
  float: left;
}
#resume > h3 {
  float: left;
}
.res-container {

  margin-left: 100px;
  margin-right: auto;
}
.skill-container{
  float: right;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-row-gap: 15px;
  font-size: 5em;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.no-deco:hover {
  text-decoration: none;
}
.skill-logo{
  margin: 0;
}
.skill-desc {
  font-size: 15px;
  margin-top: -15px;
}
#social > h3 {
  float: left;
}
.social-container {
  display: flex;
  justify-content: space-evenly;
}
#bk-logo {
  width: 25%;
}
.social-link:hover {
  transform: scale(105%);
}
.form-container {
  margin: 50px auto 10px auto;
  width: 80%;
}
#res-btn {
  color: black;
  margin-top: 5px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
#main-nav {
  margin-top: 0;
}
#bk-image {
  width: 25%;
  height: 25%;
  border-radius: 25px;
  margin: 10px;
}
.ui.item.menu {
  background-color: #222;
}
.ui.item.menu > a{
  color: darkgray
}
.ui.item.menu > a:hover{
  color: red;
}
@media screen and (min-width:768px) {
  #bk-logo {
    width: 10%;
  }
  #bk-image {
    width: 15%;
  }
}