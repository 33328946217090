#nav {
    grid-area: 2;
    height: 80vh;
}
ul {
    display: flex;
    justify-content: space-evenly;
}
li {
    list-style: none;
}